import Navbar from './components/Navbar';
import Home from './views/Home';
import List from './views/List';
import Create from './views/Create';
import Edit from './views/Edit';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

function App() {
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const info = window.sessionStorage.getItem('APP_INFO_RESTART');

    if (!info || info !== '1') {
      setShowInfo(true);
    }
  }, []);

  function dontShowInfoForThisSession() {
    window.sessionStorage.setItem('APP_INFO_RESTART', '1');
    setShowInfo(false);
  }

  return (
    <Router>
      <ToastContainer />
      {showInfo && (
        <div className='h-10 bg-gray-800 text-lg text-white flex justify-center items-center'>
          <FontAwesomeIcon icon={faInfo} className='mx-2' />
          Le serveur redémarrera toutes les 24h
          <button className='button-small mx-2' onClick={dontShowInfoForThisSession}>
            Ok
          </button>
        </div>
      )}
      <Navbar />

      <Route path='/' component={Home} exact />
      <Route path='/list' component={List} />
      <Route path='/create' component={Create} />
      <Route path='/edit/:id' component={Edit} />
    </Router>
  );
}

export default App;
